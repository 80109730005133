import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
} from './FooterElements';
import { FaLinkedin } from "react-icons/fa";

export default function Footer() {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <FooterContainer>
            <FooterWrap>    
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            NewsWave
                        </SocialLogo>
                        
                        <WebsiteRights>
                            NewsWave © {new Date().getFullYear()} All rights reserved.
                        </WebsiteRights>

                        {/* <SocialIcons>
                            <SocialIconLink
                                href='https://www.google.com/'
                                target='_blank'
                                area-label="Linkedin"
                            >
                                <FaLinkedin />
                            </SocialIconLink>
                        </SocialIcons> */}
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    );
};