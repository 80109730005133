import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { BtnFill, BtnOutline } from "../ButtonElement";
import {
    Nav,
    NavContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtnMenu
} from './NavbarElements';

export default function Navbar({ toggle }) {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <Nav scrollNav={scrollNav} >
                <NavContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        NewsWave
                    </NavLogo>

                    <MobileIcon onClick={ toggle }>
                        <FaBars />
                    </MobileIcon>

                    <NavMenu>
                        <NavItem>
                            <NavLinks
                                to='about'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >
                                About
                            </NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks
                                to='discover'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >
                                Discover
                            </NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks
                                to='services'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >
                                Services
                            </NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks
                                to='contact'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >
                                Contact
                            </NavLinks>
                        </NavItem>
                    </NavMenu>

                    <NavBtnMenu>
                        <BtnFill
                            to='signup'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}

                            fontSize="medium"
                            btnSize="medium"
                            backgroundColor="#0C4F7E"
                            fontColor="white"
                        >
                            Sign Up
                        </BtnFill>
                    </NavBtnMenu>
                </NavContainer>
            </Nav>
        </>
    );
};