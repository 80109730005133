import React from "react";
import styles from "./EarlyAccessForm.module.css";

export default function EarlyAccessForm() {
    return (
        <div id='signup' className={styles['container']}>
            <div className={styles['form-wrapper']}>
                {/* Tally form!! */}
                <iframe
                    className={styles['tally-form']}
                    src="https://tally.so/embed/3xjJB5?alignLeft=1"
                    loading="lazy"
                    title="Sign up for updates and early access."
                >
                </iframe>
            </div>
        </div>
    );
};