import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import {
	HeroContainer,
	HeroBg,
	VideoBg,
	ImageBg,
	HeroContent,
	HeroH1,
	HeroP,
	HeroBtnWrapper,
	ArrowForward,
} from './HeroElements';
import { BtnOutline } from '../ButtonElement';

// REALLY GOOD WEBSITE FOR FREE LOOPING VIDEOS
// https://pixabay.com/videos/search/looping/
import Video from '../../videos/CircleWave.mp4';
import Image from '../../images/CircleWaveImg.png';

export default function HeroSection({ title, subtitle }) {
	// useEffect(() => {
	//     let x = document.getElementById("main-video");
	//     x.playbackRate = 1;
	// }, []);

	const ImageBgActual = <ImageBg src={Image} />;
	const VideoBgActual = (
		<VideoBg
			id='main-video'
			autoPlay
			loop
			muted
			src='https://newswave-static.s3.us-east-2.amazonaws.com/videos/CircleWave.mp4'
			type='video/mp4'
		/>
	);

	return (
		<HeroContainer>
			<HeroBg>{isMobile ? ImageBgActual : VideoBgActual}</HeroBg>

			<HeroContent>
				<HeroH1>{title}</HeroH1>

				<HeroP>{subtitle}</HeroP>

				<HeroBtnWrapper>
					<BtnOutline
						to='signup'
						smooth={true}
						duration={500}
						spy={true}
						exact='true'
						offset={-80}
						fontSize='medium'
						btnSize='medium'
						outlineColor='white'
						backgroundColor='white'
						fontColor='black'
					>
						Sign Up <ArrowForward />
					</BtnOutline>
				</HeroBtnWrapper>
			</HeroContent>
		</HeroContainer>
	);
}
