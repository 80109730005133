import React from 'react';
import { BtnFill, BtnOutline } from '../ButtonElement';
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SidebarBtnMenu,
    SideBtnWrap
} from './SidebarElements';

export default function Sidebar({ isOpen, toggle }) {
    return (
        <>
            <SidebarContainer isOpen={ isOpen } onClick={ toggle }>
                <Icon onClick={ toggle }>
                    <CloseIcon />
                </Icon>

                <SidebarWrapper>
                    <SidebarMenu>
                        <SidebarLink to='about' onClick={ toggle } offset={-80}>
                            About
                        </SidebarLink>

                        <SidebarLink to='discover' onClick={ toggle } offset={-80}>
                            Discover
                        </SidebarLink>

                        <SidebarLink to='services' onClick={ toggle } offset={-80}>
                            Services
                        </SidebarLink>

                        <SidebarLink to='contact' onClick={ toggle } offset={-80}>
                            Contact
                        </SidebarLink>
                    </SidebarMenu>
                    
                    <SidebarBtnMenu>
                        <SideBtnWrap>
                            <BtnFill
                                to='signup'
                                onClick={ toggle }
                                offset={-80}

                                fontSize="large"
                                btnSize="large"
                                backgroundColor="#0C4F7E"
                                fontColor="white"
                            >
                                Sign Up
                            </BtnFill>
                        </SideBtnWrap>
                    </SidebarBtnMenu>
                </SidebarWrapper>
            </SidebarContainer>
        </>
    );
};