import React from 'react';
import {
	ServicesContainer,
	ServicesH1,
	ServicesWrapper,
	ServicesCard,
	ServicesIcon,
	ServicesH2,
	ServicesP,
} from './ServicesElements';

export default function ServicesSection({
	id,
	picOne,
	picTwo,
	picThree,
	sectionTitle,
	headerOne,
	textOne,
	headerTwo,
	textTwo,
	headerThree,
	textThree,
}) {
	return (
		<ServicesContainer id={id}>
			<ServicesH1>{sectionTitle}</ServicesH1>

			<ServicesWrapper>
				<ServicesCard>
					<ServicesIcon src={picOne} />

					<ServicesH2>{headerOne}</ServicesH2>

					<ServicesP>{textOne}</ServicesP>
				</ServicesCard>

				<ServicesCard>
					<ServicesIcon src={picTwo} />

					<ServicesH2>{headerTwo}</ServicesH2>

					<ServicesP>{textTwo}</ServicesP>
				</ServicesCard>

				<ServicesCard>
					<ServicesIcon src={picThree} />

					<ServicesH2>{headerThree}</ServicesH2>

					<ServicesP>{textThree}</ServicesP>
				</ServicesCard>
			</ServicesWrapper>
		</ServicesContainer>
	);
}
