import Image1 from './images/info1.svg';
import Image2 from './images/info2.svg';
import Image3 from './images/feedback.svg'
import Icon1 from './images/services1.svg';
import Icon2 from './images/services2.svg';
import Icon3 from './images/services3.svg';

export const heroData = {
	// change this
	title: 'Harness qualitative data. Enhance your investment process.',
	subtitle:
		'NewsWave synthesizes alternative data and generates insights using AI. Take advantage of our many services to make better decisions today.',
};

export const sectionOne = {
	id: 'about',
	background: 'linear-gradient(black, #0C4F7E)',
	imgStart: false,
	primaryTextColor: 'white',
	secondaryTextColor: '#d3d3d3',
	btnBackground: '#d3d3d3',
	btnTextColor: 'black',

	// change below
	topLine: 'Unified Platform',
	headline: 'Everything qualitative in one place',
	description:
		'NewsWave aggregates relevant news, sell-side reports, product reviews, employee reviews, and social media sentiments from thousands of sources.',
	buttonLabel: 'Sign Up',
	img: Image1,
	alt: 'Image Missing',
};

export const sectionTwo = {
	id: 'discover',
	background: '#0C4F7E',
	imgStart: true,
	primaryTextColor: 'white',
	secondaryTextColor: '#d3d3d3',
	btnBackground: '#d3d3d3',
	btnTextColor: 'black',

	// change below
	topLine: 'Enhanced Analysis',
	headline: 'Go deeper than the financials',
	description:
		"NewsWave helps you understand what a company's employees and customers think about it. Do people like working there? Was the new product a hit? Find out with NewsWave.",
	buttonLabel: 'Sign Up',
	img: Image2,
	alt: 'Image Missing',
};

export const sectionThree = {
	id: 'services',
	picOne: Icon1,
	picTwo: Icon2,
	picThree: Icon3,

	// change below
	sectionTitle: 'The NewsWave Difference',
	headerOne: 'Aggregation',
	textOne:
		'We deliver valuable information faster by filtering alternate data from thousands of sources.',
	headerTwo: 'Analysis',
	textTwo:
		'We provide context about every piece of information so you can make more informed decisions.',
	headerThree: 'Presentation',
	textThree:
		"We encapsulate each company's qualitative health in cohesive dashboards.",
};

export const sectionFour = {
	id: 'contact',
	background: '#0C4F7E',
	imgStart: true,
	primaryTextColor: 'white',
	secondaryTextColor: '#d3d3d3',
	btnBackground: '#d3d3d3',
	btnTextColor: 'black',
	emailAddress: 'contact@newswave.app',

	headline: 'Contact Us',
	description:
		"Have any questions, concerns, or feedback? Feel free to contact us on Twitter/X @NewsWaveNews, or click the button below to send us an email.",
	buttonLabel: 'Email Us',
	img: Image3,
	alt: 'Image Missing',
};
