import React, { useState } from 'react';

// components
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import ServicesSection from '../components/ServicesSection';
import EarlyAccessForm from '../components/EarlyAccessForm';
import Footer from '../components/Footer';
import { Line } from '../components/LineElement';

import { heroData, sectionOne, sectionTwo, sectionThree, sectionFour } from '../Data';

export default function Landing() {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			{/* navigation */}
			<Sidebar isOpen={isOpen} toggle={toggle} />
			<Navbar toggle={toggle} />

			{/* sections */}
			{/* NOTE: Will add sections between main sections later */}
			{/* That way the links will go to about, discover, and services, */}
			{/* but there will be more than one section within each part */}
			<HeroSection {...heroData} />
			<Line color='grey' />
			<InfoSection {...sectionOne} />
			<Line color='grey' />
			<InfoSection {...sectionTwo} />
			<Line color='grey' />
			<ServicesSection {...sectionThree} />
			<Line color='grey' />
			<InfoSection {...sectionFour} />
			<Line color='grey' />
			<EarlyAccessForm />
			<Footer />
		</>
	);
}
