import styled from "styled-components";
import { Link } from 'react-scroll';

export const BtnOutline = styled(Link)`
    // base styles
    font-size: ${(props) => 
        (props.fontSize === "small" && '12px') ||
        (props.fontSize === "medium" && '16px') || 
        (props.fontSize === "large" && '24px')
    };
    padding: ${(props) => 
        (props.btnSize === "small" && '10px 22px') ||
        (props.btnSize === "medium" && '12px 30px') || 
        (props.btnSize === "large" && '14px 48px')
    };
    border-radius: 50px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    // outline styles
    border: 1px solid white;
    border-color: ${(props) => (props.outlineColor)};
    color: ${(props) => (props.outlineColor)};
    background: transparant;

    &:hover {
        // base styles
        transition: all 0.2s ease-in-out;

        // outline styles
        background: ${(props) => (props.backgroundColor)};
        color: ${(props) => (props.fontColor)};
    }
`;

export const BtnFill = styled(Link)`
    // base styles
    font-size: ${(props) => 
        (props.fontSize === "small" && '12px') ||
        (props.fontSize === "medium" && '16px') || 
        (props.fontSize === "large" && '24px')
    };
    padding: ${(props) => 
        (props.btnSize === "small" && '10px 22px') ||
        (props.btnSize === "medium" && '12px 30px') || 
        (props.btnSize === "large" && '14px 48px')
    };
    border-radius: 50px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    // filled styles
    outline: none;
    border: none;
    background: ${(props) => (props.backgroundColor)};
    color: ${(props) => (props.fontColor)};

    &:hover {
        // base styles
        transition: all 0.2s ease-in-out;

        // filled styles
        opacity: 0.7;
    }
`;