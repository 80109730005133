import React from "react";
import { BtnFill } from "../ButtonElement";
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img
} from "./InfoElements";

export default function InfoSection({
    id,
    background,
    imgStart,
    primaryTextColor,
    secondaryTextColor,
    btnBackground,
    btnTextColor,
    topLine,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    emailAddress
}) {
    return (
        <>
            <InfoContainer id={id} background={background}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine secondaryTextColor={secondaryTextColor}>
                                    {topLine}
                                </TopLine>
                                
                                <Heading primaryTextColor={primaryTextColor}>
                                    {headline}
                                </Heading>

                                <Subtitle primaryTextColor={primaryTextColor}>
                                    {description}
                                </Subtitle>

                                <BtnWrap>
                                  {/* Check if emailAddress is provided */}
                                  {emailAddress ? (
                                    <a
                                      href={`mailto:${emailAddress}`}
                                      style={{
                                        display: 'inline-block',
                                        textDecoration: 'none',
                                        backgroundColor: btnBackground,
                                        color: btnTextColor,
                                        padding: '10px 20px',
                                        borderRadius: '5px',
                                      }}
                                    >
                                      {buttonLabel}
                                    </a>
                                  ) : (
                                    <BtnFill
                                        to='signup'
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact='true'
                                        offset={-80}

                                        fontSize='medium'
                                        btnSize='medium'
                                        backgroundColor={btnBackground}
                                        fontColor={btnTextColor}
                                    >
                                        {buttonLabel}
                                    </BtnFill>
                                  )}
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>

                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    );
};